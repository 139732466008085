import * as React from 'react'
import './subscribed.css'

export const Subscribed = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 26.742 26.742">
    <g id="Group_6356" data-name="Group 6356" transform="translate(-446.532 -373.344)">
        <g id="Group_6330" data-name="Group 6330" transform="translate(446.532 373.344)">
            <g id="Group_6327" data-name="Group 6327">
                <path id="Path_21284" d="M682.241 201.28a8.492 8.492 0 0 0-6.092 5.72 12.117 12.117 0 0 0 1.314 1.014c-.1-.1-.207-.194-.307-.291a10.109 10.109 0 0 1 5.085-6.443z" className="subscribed-1" data-name="Path 21284" transform="translate(-670.911 -184.594)"/>
                <path id="Path_21285" d="M680.074 195.666a4.956 4.956 0 0 1 3.768-4.492c-.041 0-.078-.012-.12-.012a4.249 4.249 0 0 0-.732 8.435 4.435 4.435 0 0 1-2.916-3.931z" className="subscribed-1" data-name="Path 21285" transform="translate(-670.351 -186.301)"/>
                <path id="Path_21286" d="M684.862 188.043a12.17 12.17 0 0 0-12.155 12.157 12.023 12.023 0 0 0 3.079 8.071 9.718 9.718 0 0 1 18.154 0 12.023 12.023 0 0 0 3.078-8.071 12.17 12.17 0 0 0-12.156-12.157zm0 13.371a5.47 5.47 0 1 1 5.471-5.47 5.476 5.476 0 0 1-5.471 5.47z" className="subscribed-1" data-name="Path 21286" transform="translate(-671.492 -186.827)"/>
                <path id="Path_21287" d="M687.889 195.416a4.254 4.254 0 0 0-4.135-4.243 4.956 4.956 0 0 0-3.768 4.492 4.435 4.435 0 0 0 2.914 3.935 4.208 4.208 0 0 0 .732.074 4.259 4.259 0 0 0 4.257-4.258z" className="subscribed-2" data-name="Path 21287" transform="translate(-670.263 -186.299)"/>
                <path id="Path_21288" d="M683.9 190.123a5.47 5.47 0 1 0 5.471 5.47 5.477 5.477 0 0 0-5.471-5.47zm-4.254 5.47a4.259 4.259 0 0 1 4.254-4.254c.042 0 .079.011.12.012a4.249 4.249 0 0 1-.12 8.5 4.206 4.206 0 0 1-.732-.074 4.254 4.254 0 0 1-3.526-4.184z" className="subscribed-3" data-name="Path 21288" transform="translate(-670.526 -186.476)"/>
                <path id="Path_21289" d="M684.136 201.044a8.389 8.389 0 0 0-2.041.276 10.109 10.109 0 0 0-5.085 6.442c.1.1.206.194.307.291a12.123 12.123 0 0 0 14.951-1.014 8.513 8.513 0 0 0-8.132-5.995z" className="subscribed-2" data-name="Path 21289" transform="translate(-670.765 -184.633)"/>
                <path id="Path_21290" d="M698.409 200.374a13.371 13.371 0 0 0-26.742 0 13.229 13.229 0 0 0 3.879 9.4l-.006.021.259.247a13.329 13.329 0 0 0 17.132 1.12 13.714 13.714 0 0 0 1.345-1.118l.256-.243-.006-.021a13.229 13.229 0 0 0 3.883-9.406zm-13.371 12.156a12.436 12.436 0 0 1-8.132-3.122 8.492 8.492 0 0 1 6.094-5.719 8.389 8.389 0 0 1 2.041-.276 8.513 8.513 0 0 1 8.132 5.995 12.1 12.1 0 0 1-8.135 3.122zm9.078-4.085a9.718 9.718 0 0 0-18.154 0 12.023 12.023 0 0 1-3.079-8.071 12.156 12.156 0 0 1 24.311 0 12.023 12.023 0 0 1-3.079 8.071z" className="subscribed-3" data-name="Path 21290" transform="translate(-671.667 -187.003)"/>
            </g>
        </g>
    </g>
</svg>
